



























































import { defineComponent, computed, ref } from '@vue/composition-api';
import store, { useUserGetters, useUserActions } from '@/store';
import { ActionTypes } from '@/store/modules/tools/actions';

export default defineComponent({
  name: 'ActivityFeedbackForm',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    adkName: {
      type: String,
      default: ''
    },
    studentId: {
      type: String,
      default: ''
    }
  },
  setup(props, ctx) {
    const { getProgramDoc } = useUserGetters(['getProgramDoc']);
    const { saveActivityFeedback } = useUserActions(['saveActivityFeedback']);
    const showDialog = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('toggle');
      }
    });
    const remarks = ref('');
    const isLoading = ref(false);
    const ratingValue = ref();

    const getFeedBackData = () => {
      const programDoc = getProgramDoc?.value;
      const adkObject = programDoc?.adks?.find(adk => adk?.name === props?.adkName);
      if (adkObject?.remarks) remarks.value = adkObject?.remarks;
      if (adkObject?.ratings) ratingValue.value = adkObject?.ratings;
    };

    getFeedBackData();

    async function saveFeedback() {
      try {
        isLoading.value = true;
        const data = {
          student_id: props?.studentId.toString(),
          adk_name: props?.adkName,
          ratings: ratingValue?.value,
          remarks: remarks.value
        };
        const res = await saveActivityFeedback(data);
        console.log(res);
        if (res && res?.data) {
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            message: res?.message,
            type: 'success',
            isShowSnackbar: true
          });
          isLoading.value = false;
          ctx.emit('toggle');
        }
      } catch (error) {
        console.log(error);
        isLoading.value = false;
      }
    }

    return {
      showDialog,
      saveFeedback,
      isLoading,
      remarks,
      ratingValue
    };
  }
});
